body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
//import partagé avec d'autres apps
@import "./sass/reset.scss";
@import "./sass/variables.scss";

//charte graphique variables de couleurs
@import "./sass/colors.scss";

//mixins
@import "./sass/mixins/films_box_mixin";
//ui mixins
@import "./sass/ui/buttons.scss";
//composants du site
@import "./sass/App.scss";
@import "./sass/components/header.scss";
@import "./sass/components/logo.scss";
@import "./sass/components/main_content.scss";
@import "./sass/components/films.scss";
@import "./sass/components/film.scss";
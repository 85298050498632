$movie_clip: "../img/movie-clip.svg";
header {
    background-color:$header-bg-color;
    background-image:url($movie_clip);
    position:fixed;
    top:0;
    left:0;
    right:0;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-left:2rem;
    padding-right:2rem;
    z-index:1000;
    .menu_large {
        //background-color:rgba(0, 0, 0, 0.459);
        border-radius:3px;
        
        color:white;
        
        ul {
            padding:0.5rem;
            padding-inline-start:0;
            margin-block-start:0;
            margin-block-end:0;
            margin:0.8rem 0 0.8rem 0;
            li {
                padding: 0 0.5rem 0 0.5rem;
                display: inline-block;
                list-style: none;
                @include button();
            }
        }

    }
}
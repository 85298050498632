// app colors 
$body-bg-color:rgb(0,0,0);
$body-color:rgb(255,255,255);
$header-bg-color:rgba(255, 255, 255, 0.562);
$footer-bg-color:rgba(255, 255, 255, 0.562);
//admin colors
$admin-header-bg-color:rgba(0, 0, 0, 0.596);
$admin-header-color:rgb(92, 0, 0);

//ENSEMBLE characters
//personnages box
$characters-box-bg:rgba(0, 0, 255, 0.103);
$characters-box-border:2px solid black;
$characters-box-title-color:white;

//liste de personnages
$characters-list-title-bg:rgba(255, 255, 255, 0.74);
$characters-list-impairs-bg:rgb(150, 225, 255);
$characters-list-pairs-bg:rgb(150,255, 225);

//ENSEMBLE RACES
//races box
$races-box-bg:rgba(0, 0, 255, 0.103);
$races-box-border:2px solid black;
$races-box-title-color:white;

//liste de races
$races-list-title-bg:rgba(255, 255, 255, 0.74);
$races-list-impairs-bg:rgb(150, 225, 255);
$races-list-pairs-bg:rgb(150,255, 225);
.film_box {
    //background-color:green;
    padding: 1rem;
    .film {
        border: 5px solid black;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.75);
        margin-top: 0rem;
        margin-bottom: 1rem;
        height: 100%;
        //background-color:yellow;
        display: flex;
        flex-direction: column;
        //padding:1rem;
        .film_poster_box {
            background-color: black;
            //padding:0.5rem;
            position: relative;
            display: flex;
            flex-direction: column;
            .film_poster_box_img img {
                width: 100%;
            }

            .film_poster_box_top {
                position: absolute;
                background-color: rgba(255, 255, 255, 0.8);
                color: black;
                padding-left: 1rem;
                top: 0;
                left: 0;
                right: 0;
            }
            .film_poster_box_bot {
                position: absolute;
                //background-color: rgba(255, 255, 255, 0.2);
                bottom: 5px;
                left: 0;
                right: 0;
                display: flex;
                justify-content: space-between;
            }
        }
        .film_videos {
            padding: 1rem;
        }
        .film_description {
            padding: 1rem;
        }
        &:hover {
            margin-top: 1rem;
            margin-bottom: 0;
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
        }
        .video_separator {
            background-image: url($movie_clip);
            height: 1rem;
        }
    }
}
.notes {
    padding:0.5rem;
    background-color:rgba(255, 255, 255, 0.5);
    border-radius:10px 0 0 0;
    padding-right:0.5rem;
    font-weight:bold;
    .note_imdb {
        color: black;
        //background-color:yellow;
        display: flex;
        justify-content: space-between;
    }
    .note {
        color: black;
        display:flex;
        justify-content: space-between;
    }
    p {
        padding-left:0.5rem;
        margin-block-start:0;
        margin-block-end:0;
    }
}
